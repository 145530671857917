.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: inherit;
}

body:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.50);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
  }
}

@media screen and (max-width: 1080px) {
  .mega-container {
    flex-direction: column;
    height: auto;
  }
  .App-body {
    height: auto;
  }
  .body-container {
    height: auto;
  }
  .json-output-container {
    height: 400px; 
    width: 300px;
    maxWidth: 300px;
  }
  .map-container {
    height: auto;
  }
  .ip-input {
    width: 300px;
  }
  .map-inner-container {
    height: 400px;
    width: 300px
  }
}

@media screen and (min-width: 1080px) {
  .mega-container {
    flex-direction: row;
    height: 100%
  }
  .body-container {
    height: 100%;
  }
  .json-output-container {
    height: 100%; 
    width: 500px;
    maxWidth: 500px;
  }
  .map-container {
    height: 100%
  }
  .ip-input {
    width: 500px;
  }
  .map-inner-container {
    height: 500px;
    width: 500px
  }
}

.App-header {
  height: 16vh;
  /*background-color: #ffffff;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.App-body {
  color: #000000;
}

.App-footer {
  padding: 10px;
  margin: 10px;
  height: 5vh;
}

.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}

.json-output-container {
  margin-right: 5px;
  /*overflow: scroll;*/
  text-align: left;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.map-container {
  margin-left: 5px;
  flex-direction: column;
}

.mega-container {
  box-shadow: 0px 0px 6px 1px #1f1f1f;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0 5px 0;
  display: flex;
}

.closed-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closed {
  text-align: center;
  z-index: 3;
  font-size: 48px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #d0d0d0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
